<template>
  <div class="layout-content">
    <el-row style="text-align: center;">
      <h3>登录</h3>
    </el-row>
    <el-row type="flex" justify="space-around" align="middle">
      <el-col :xs="24" :sm="18" :md="14" :lg="10">
        <el-form :model="form" status-icon ref="form" label-width="100px" class="demo-form">
          <el-form-item label="用户名" prop="name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pwd">
            <el-input type="password" v-model="form.pwd" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="resetForm('form')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { store } from "../store";
// import axios from "axios";
const host = process.env.VUE_APP_HOST_URL;

export default {
  mounted() {
    // 切换主题
    store.setTheme("light");
    console.log(this.$route);
  },
  data() {
    return {
      form: {
        name: "",
        pwd: "",
      },
    };
  },
  methods: {
    async submitForm() {
      if (!this.form.name || !this.form.pwd) {
        return;
      }
      // let that = this;
      // console.log("redirect:", this.$route.query.redirect);
      let resp = await this.axios.post(`${host}/login`, {
        username: this.form.name,
        password: this.form.pwd,
      });
      console.log(resp);
      // console.log(this.$router);
      let redirect = this.$route.query.redirect;
      // console.log("redirect", redirect);
      this.$router.push(
        redirect,
        () => {},
        () => {
          this.$notify({
            title: "登录失败",
            message: "跳转失败",
          });
        },
      );
    },
    resetForm() {
      this.$refs["form"].resetFields();
    },
  },
};
</script>

<style lang="less" slot-scope></style>
